import m from 'mithril'
import uuid from 'uuid'
import stream from 'mithril/stream'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {
    email: {
        presence: {
            allowEmpty: false,
            message: "^請輸入註冊用電子信箱"
        },
        email: {
            message: "^電子信箱格式不正確"
        }
    },
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入姓名"
        }
    },
}

class Manager extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {}
        this.id = args.id || 0
        this.question = args.question || 0
        this.a = args.a || 0
        this.b = args.b || 0
        this.c = args.c || 0
        this.d = args.d || 0
        this.e = args.e || 0
        this.total = args.total || 0
    }

    static get data() {
        return _data;
    }

    static fetch() {
        Manager.loading = true
        return m.request({
            method: 'GET',
            url: `/api/test`
        }).then((response) => {
            Manager.loading = false
            return response
        })
    }

    static load(id) {
        Manager.loading = true
        return m.request({
            method: 'GET',
            url: `/api/test/${id}`
        }).then((response) => {
            Manager.loading = false
            return response
        })
    }

    static save(question, select) {
        Manager.loading = true
        return m.request({
            method: 'POST',
            url: `/api/test`,
            body: [question, select]
        }).then((response)=>{
            Manager.loading = false
        })
    }
}

export default Manager